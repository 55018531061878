import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/components/default-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`KROHNE Group – Terms and Conditions`}</h1>
    <h3>{`Standard Terms and Conditions of Sale and Delivery of the KROHNE Group`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`General`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`These Standard Terms and Conditions of Sale and Delivery shall apply to all deliveries and other services provided by any company of the KROHNE Group (“Seller”). Buyer’s Terms and Conditions are only applicable in whole or in part, if expressly accepted by Seller in writing per letter, telecopy or email.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case certain provisions of these Standard Terms and Conditions of Sale and Delivery will be regarded as invalid, the validity of the remaining provisions of these Standard Terms and Conditions of Sale and Delivery and of the Sales Contract shall not be affected. Buyer and Seller will replace the invalid provisions by such valid provisions being legally permitted and corresponding as close as possible to the legal and economic sense and purpose of the invalid provisions.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Waiving any special notice, the Buyer authorizes the Seller to use personal data to the extent permitted by any applicable statutory law governing the protection of personal data and necessary for the implementation of the Sales Contract and to disclose such personal data to the Seller’s personnel involved in the implementation of the Sales Contract.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Offers, extent of delivery and conclusion of contract`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`All offers are subject to alteration.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Sales Contract takes effect when the Seller’s written order confirmation is dispatched (per mail, telecopy or email). The order confirmation of Seller shall also be valid without hand-written signature. Further alterations of the Sales Contract require the Seller’s written confirmation per letter, telecopy or email. In case the delivery had to take place without prior written order confirmation, the Sales Contract takes effect upon the delivery. Confirmed orders cannot be put on Hold or cancelled without Seller’s written approval.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The order confirmation shall govern the extent of the deliveries to be performed according to the Sales Contract. Any information regarding the quality or characteristics of the product outside the order confirmation, e.g. in advertising material, shall be without any legal significance. The Seller reserves the right to alter the construction, the choice of material, the specification and the design even after dispatch of the order confirmation to the extent that such alterations are not in contradiction to the order confirmation.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Any documents relating to the offer or the order confirmation, such as sketches, drawings, dimensions and weights shall not be binding unless they are expressly specified in writing to be binding.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case of material changes in the financial condition of the Buyer compared to the financial condition at the moment of dispatch of the order confirmation, Seller shall be entitled to refuse the deliver until the Buyer has obtained corresponding security.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Prices and payment conditions`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The prices are free carrier, ex works (FCA) excluding packaging and other costs for handling and transportation. The packaging shall be calculated at prime cost and cannot be returned. If applicable the prices are increased by the value added or sales tax as required by law.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case of a material and unforeseeable alteration of the product costs not under control of the Seller, the Seller reserves the right to agree with the Buyer on a price deviating from the order confirmation.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case of changes required by the Buyer after dispatch of the order confirmation, any potential additional costs will be invoiced to the Buyer.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Any payments shall be effected within 30 days after the date of invoice without any deduction.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Upon delayed payment, Seller reserves the right to charge interest on any overdue sums at 8% above the local bank base lending rate (or such higher rate stipulated by applicable law) during the period of delay; and to suspend performance of the Contract (including withholding any further shipments) in the event that Buyer fails or in Sellers reasonable opinion it appears that Buyer is likely to fail to make payment when due under the Contract or any other contract; and at any time require such reasonable security for payment as Seller may deem reasonable.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Bills of exchange and cheques do not count as payment until they are cashed. Discount, bank and other charges shall be borne by the Buyer.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Buyer shall not have the right of retention of payments. Any off-set or counterclaim is only permitted insofar as these counterclaims are acknowledged by the Seller as existing and due or have been finally determined as legally binding.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Delivery terms, acceptance and dispatch`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The Seller will endeavour to comply with the confirmed delivery time. The statements regarding delivery times are made according to best discretion without being binding unless the delivery date has been agreed upon in the order confirmation as a fixed day.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The delivery time commences after technical and commercial clarification of the order and submittal of required approvals with the dispatch of the order acknowledgement. If the Buyer does not fulfil its material contractual and payment obligations the delivery time shall be extended reasonably. Compliance with the delivery time has been reached when the Buyer has been notified within the agreed delivery time of the readiness of the order for shipment. Any alterations of the order demanded by the Buyer within that delivery time shall interrupt or extend the delivery time accordingly.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Unforeseeable events entitle the Seller to defer delivery for so long as the impediment persists plus an appropriate additional period. Unforeseeable events are such circumstances as those which cannot be avoided by reasonable care due to circumstances, in particular cases, such as war, currency and economical or other sovereign measures, civil disturbance, natural events, fire, strikes, lock-outs, non-supply of materials arising through no fault of the Seller, traffic obstructions, operation interruptions, and other events of force majeure, through which the fulfilment of the Sales Contract is endangered, made substantially more difficult or rendered impossible. In such cases, the Seller is entitled to withdraw from the Contract without any obligation to pay damages. The Buyer may demand a statement from the Seller as to whether the Seller will withdraw or deliver within an appropriate period. If no statement is given by the Seller, the Buyer may withdraw from the Sales Contract. The Buyer may not refuse partial deliveries or partial fulfilment.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If deliveries are delayed for other reasons, the Buyer shall grant an appropriate extension of time in writing. Upon expiration of such an extension without the goods being shipped by the Seller, the Buyer is entitled to cancel those supplies, which have not been shipped or declared ready for shipment prior to the expiration of such an extension. In case the deliveries, which have already been partially performed, are of no practical interest to the Buyer, he shall be entitled to withdraw from the entire Sales Contract.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Claims of the Buyer for damages are in all cases of delayed delivery or order fulfilment excluded. This shall not apply if the Seller is liable for wilful actions or gross negligence.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case that no fixed dates for acceptance have been agreed upon, the Buyer is committed to accept the delivered goods within 8 days after completion.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If the Buyer has submitted an “open purchase order”, he is committed to accept the ordered products - in case of several different products all products - within 12 months after the date of order. Sec. 4.5 shall apply correspondingly.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If the Buyer does not accept the goods at the agreed date, he shall nevertheless be obliged to pay the purchase price. Notwithstanding any further legal rights, the Seller shall be entitled to arrange for storage of the goods at the expense and risk of the Buyer or to dispose otherwise about the goods and to perform a delivery to the Buyer at the next possible date. The Seller will charge the minimum of a ½ % of the order value per month for storage.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The goods will be shipped ex works at the expense and risk of the Buyer. If not otherwise agreed upon, using his best judgment, the Seller determines the carrier and the method of shipment. The Seller will only, at the expense of the Buyer, insure against transport damages, theft or loss if expressly required by the Buyer.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Rights to use of the Buyer for software`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The following terms of Sec. 5 shall apply exclusively to the provision of standard software for a limited or unlimited period as a part of or in connection with related hardware (such software hereinafter referred to as “Software”), as well as to the entire Supplies, to the extent that a breach of contract has its cause in the Software. The General Standard Terms and Conditions of Sale and Delivery shall apply to those matters as far as not specifically covered in the Sec. The Seller does not assume any obligation to perform software services by virtue these terms in Sec. Such services require a separate agreement.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Firmware is not “Software” within the meaning of Sec. To the extent that Software is provided to the Buyer, for which the Seller is only derived rights to use (third party software), the provisions of this Sec. 5 shall be amended and superseded by the conditions of use agreed between the Seller and its licensor. To the extent that the Buyer is provided with open source Software, the provisions of this Sec. 5 shall be amended and superseded by the conditions of use underlying the open source Software. The superseding conditions of use will be announced or published online to the Buyer.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Seller supplies a copy of the Software in digital form on a data medium or online to the Buyer. The delivery of documentation has to be agreed upon in writing. If documentation is supplied, the term “Software” as used hereinafter shall also include the documentation. The Supplier shall also be entitled to provide the documentation only online, if the Software is supplied on a data medium. The Buyer is obligated to install the Software by himself, to check the efficiency and give notification of defects without undue delay. In case a license key is needed for the use of the Software, it will be transferred to the Buyer by digital form. The license key is personalized and shall only be used by the Buyer for the use of the acquired Software. An assignment of the license key is only allowed under the terms of Sec. 10.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Seller grants the Buyer the non-exclusive right to use the Software. The right is limited to the agreement period of time, the absence of such agreement, the right to use shall be unlimited in time.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Buyer shall use the Software solely on the hardware referred to in the contract documents, in the absence of such reference, the use shall be limited to the respective hardware supplied together with the Software. The use of the Software on any other device shall require the express prior written consent of the Seller and shall, if used on a more powerful device, entitle the Seller to claim an appropriate additional remuneration; this does not apply, however, to the extent and for the period, in which the Buyer uses a temporary substitute device within the agreed scope of use, because of a defect in the agreed device.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Where the contract documents refer to more than one device, the Buyer shall not use the Software provided on more than one of these devices simultaneously (Single License), to the extent that it has not been granted a Multiple License pursuant to Sec. 11 below. Where more than one workplace exists for a specific device where the Software can be used independently, the Single License shall apply to only one workplace.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Software shall exclusively be provided in machine readable format (object code).`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Buyer shall be entitled to make only one copy of the Software and solely so for back-up purposes (back-up copy). Any other duplication on the part of the Buyer shall be allowed only subject to a Multiple License pursuant to Sec. 11 below. Any assignment, rent or lease of the Software to a third party is not allowed.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Sales as provided for in Sec. 69 (e) (decompilation) of the German Copyright Act, the Buyer shall not be entitled to modify, decompile, translate, or isolate parts of the Software. The Buyer shall not remove alphanumeric or other identifiers from the data medium and shall transfer such identifiers unchanged to any back-up copy.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In the case of rights to use provided for an unlimited period of time, in detail the following is valid: The Seller grants the Buyer the right - which shall be revocable for good cause - to assign the right to use granted to him to a third party. The Buyer to whom the Software has not been provided for commercial resale shall pass on the right to use the Software only together with the device he has bought in combination with the Software from the Seller. If the right to use is transferred to a third party, the Buyer shall ensure that the right to use granted to the third party does not exceed the scope of rights to the Software granted to the Buyer under this Agreement, and the Buyer shall ensure that the third party shall be obliged to comply with at least the same obligations as are imposed herein. When doing so, the Buyer may not retain copies of the Software. The Buyer shall not be entitled to grant sublicenses. Where the Buyer provides the Software to a third party, the Buyer shall ensure that any existing export requirements are observed and shall hold the Seller harmless in this respect.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The use of the Software on more than one device or simultaneously at more than one workplace shall require a separate agreement on the right to use. The same shall apply if the Software is used in networks even if the Software is not copied for this purpose. With regard to situations named above (hereinafter referred to as “Multiple License”), the following provisions of the Sec. 11 shall apply in addition to and with priority of the provisions of this Sec. 1 to 10:`}</p>
            <p parentName="li"><strong parentName="p">{`a)`}</strong>{` Multiple Licenses requires that the Seller expressly confirms in writing the number of admissible copies that Buyer may make of the Software provided in the number of devices and/or workplaces where the Software may be used. Sec. 5.10 second sentence shall be applicable to Multiple Licenses provided that they may be transferred by the Buyer to third parties only if transferred in the totality and together with all devices, on which the use of the Software is allowed.
`}<strong parentName="p">{`b)`}</strong>{` The Buyer shall observe the duplication rules provided by the Seller together with the Multiple License. The Buyer shall keep records on the whereabouts of all copies made and submit them to the Seller upon request.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Passing of risk`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The risk shall pass on to the Buyer upon acceptance of the goods, or on the day the acceptance is rejected without reason, or in case of passivity of the Buyer after expiration of the terms stated in Sec. 4.6 and 4.7 above, or after expiration of a separately agreed term for acceptance. In case the parties have agreed on shipment of the goods to the Buyer or a third party, the risk shall pass on to the Buyer upon transfer of the goods to a forwarding agent (carrier, railway etc.). In any case, the risk shall pass on to the Buyer in the moment he starts using the goods. If the Seller agrees to take back the goods for reasons the Seller is not responsible for, the risk shall remain with the Buyer until receipt of the goods by the Seller.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`If the Software is provided via electronic communication media (e.g. via the internet), the risk shall pass when the Software leaves the sphere of influence of the Seller (e.g. when making a download).`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Retention of title`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The goods shall remain in the property of the Seller until settlement of all claims, on whatever legal basis, even if payment has been made in respect of specially designated claims. With regard to a running account of the Buyer, the property to which the Seller has reserved title shall be the security for the outstanding balance on account.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Buyer is not entitled to pledge or transfer as security any goods being subject to retention of title. The Buyer shall immediately inform the Seller about attachments or seizures or other disposals by third parties.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In the event of the goods becoming part of or being converted into other products, the retention of title shall extend to the complete new product. In case of the goods becoming parts of or being converted into products of third parties, the Seller shall acquire the co-ownership of the new product in such percentage corresponding to the relation between the value of the Seller’s products to the other products used by the Buyer in the moment of the Seller’s goods becoming parts of or being converted into such other products. The Buyer shall be entitled to sell the goods being subject to retention of title within the ordinary course of business to third parties. In case the Buyer sells the goods without receiving simultaneously the complete purchase price against transfer of the goods, he shall be committed to agree with his customer on retention of title according to these General Terms and Conditions of Sale and Delivery. The Buyer herewith already assigns his claims and other rights based of such sale and agreement on retention of title to the Seller. At the Seller’s request, the Buyer is obliged to disclose the assignment to his customers and to provide the Seller with the appropriate information and documents being necessary for the enforcement of his rights against such customers.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case the retention of title will be qualified as invalid in the country to which the goods have been forwarded, such security for the purchase price claim of the Seller shall be deemed to have been agreed upon, which can be validly stipulated in the respective country and which corresponds as close as possible to the retention of title from the economical point of view. The Buyer shall be committed to take and perform all steps and measures insofar as actually or legally necessary.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Warranties`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Seller warrants that under normal use and service its products will function in accordance with the current product specifications if installed and operated in accordance with the accompanying installation manuals; but the Buyer is solely responsible for determining the suitability of the products for the Buyer’s use. The Buyer shall examine the goods immediately after receipt with responsible care; defects discovered shall be reported to the Seller within a preclusive period of 2 weeks in writing. Not recognizable defects shall be reported in writing to the Seller within 2 weeks after discovery.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case of a justified immediate notification of defects, a warranty will be granted either by repairing the goods (rectification of defects) or replacement of default parts (subsequent delivery). Instead the Seller shall also be entitled to compensate the decrease in value when obeying the interest of the Buyer appropriately.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Seller adheres to the basic principle that functionally adequate spare parts for each device or each important accessory part will be kept available for a period of 3 years after delivery of the last production run for the device. This regulation only applies to spare parts which are under normal operating conditions subjects to wear and tear.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case the Seller does not comply to rectify defects or to replace defective parts or the rectification or replacement fail, the Buyer shall be entitled to reduce the remuneration or, at his option, to rescind the contract.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`As regards spare parts, parts being subject to wear and tear or parts being designated to become a part of or to be converted into other products, the Buyer is committed to examine such parts and give notification of defects within the time period set forth in Sec. 1. With regard to defects which could have been noticed before installation or conversion, any warranty claims are excluded after installation and conversion.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case the Buyer requests examination of the delivered goods by the Seller and alleges a defect for which the Seller would be responsible according to Sec. 2 above, the Buyer shall be committed to bear the associated costs in case it turns out that the delivered goods are free from such defect.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Other or further claims of the Buyer based on defects including claims for damages, also with respect to consequential damages, are excluded unless otherwise expressly set forth in these Standard Terms and Conditions of Sale and Delivery. The disclaimer shall not apply in the case of injury to life, limb or health and in the case of violation of a material contractual obligation (cardinal obligation), whereby, however, in the case of violation of a cardinal obligation due to slight negligence, the Seller’s liability shall be limited to such loss or damage foreseeable and typical for this type of contract, which shall in no way include indirect losses (e.g. loss of profit). In case of non-compliance with a guarantee, which has to be expressly designated and confirmed in the order confirmation as guarantee, claims for damages can only be enforced, if it was intended that the guarantee given to the Buyer should apply precisely to damage of the kind that has occurred.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The Seller’s liability is expired, if the goods delivered have been dismantled by a third party or altered by the incorporation of parts produced elsewhere and the cause of the damages is connected with such alterations. The Seller’s liability is also expired, if the Buyer does not duly observe the Seller’s instruction for handling the goods (installation & operation instructions).`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The warranty period shall last for a period of 12 months from start-up or 18 months after passing the risk, whichever comes first.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In the case of Software provided for an unlimited period of time (Sec. 5), the following provisions shall apply additionally:`}</p>
            <ol parentName="li">
              <li parentName="ol">
                <p parentName="li">{`Software is considered to be defective only if the Buyer can prove that there are reproducible deviations from the specifications. A Defect shall not be deemed to exist if it does not manifest itself in the latest version supplied to the Buyer, and the use thereof by Buyer can reasonably be required. No Defects are such functional mall performances, which follow from the hardware and Software environment provided by the Buyer or other causes be allocated to the Buyer’s risk sphere.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Notification of Defects shall be given in writing without undue delay. Defects and the relevant data processing environment shall be described as precisely as possible therein.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Claims based on Defects do not exist in the cases of any of the following:`}</p>
                <ul parentName="li">
                  <li parentName="ul">{`insignificant deviations from the agreed characteristics,`}</li>
                  <li parentName="ul">{`only minor impairment of usability,`}</li>
                  <li parentName="ul">{`damage from faulty or negligent handling,`}</li>
                  <li parentName="ul">{`damage from particular external influences not assumed under the contract,`}</li>
                  <li parentName="ul">{`modifications made by the Buyer or third parties and any consequences resulting therefrom,`}</li>
                  <li parentName="ul">{`Software extensions made by the Buyer or a third party through the use of an interface provided by the Seller,`}</li>
                  <li parentName="ul">{`incompatibility of the Software provided with the data processing environment of the Buyer.`}</li>
                </ul>
              </li>
              <li parentName="ol">
                <p parentName="li">{`Unless the Seller chooses otherwise, the Seller will correct the Defect in the Software as follows: The Seller will provide a replacement by way of an update or an upgrade of the Software if available to the Seller or obtainable with reasonable efforts by the Seller. If the Buyer has been granted a Multiple License, it may make a corresponding number of copies of the update, or, as the case may be, upgrade. Until an update, or, as the case may be, upgrade is provided, the Seller will make available to the Buyer an interim solution by passing the Defect, provided that this does not result in unreasonable expenditures and that the Buyer would otherwise, due to the Defect, be unable to complete work that cannot be delayed. If the data medium or documentation supplied proves to be defective, the Buyer’s right shall be limited to demanding that the Seller replace it with a non-defective version. The Seller shall have the right to choose whether it corrects the Defect at the location of the Buyer or at its own location. If the Seller chooses to correct the Defect at the Buyer’s location, the Buyer shall assure that the required hardware and Software as well as the required operating conditions (including the required computing time) and qualified operating personnel are available. The Buyer shall submit to the Seller the documents and information available to it and required for Defect correction.`}</p>
              </li>
              <li parentName="ol">
                <p parentName="li">{`For Software provided for a limited period of time only, Sec. 8.10 shall apply mutatis mutandis. Sec. 8.4 shall apply except that the right of rescission shall be replaced by the right to termination without notice.`}</p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`General limitation on liability`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Unless otherwise expressly set forth in these Standard Terms and Conditions of Sale and Delivery, the Seller is only liable for damages, whatever their legal basis is, in case that they are based on a wilful action or gross negligence.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`This limitation on liability does not apply in the event the Buyer raises claims relating to personal injury or damages to property according to the Product Liability Law based on a defect of the delivered goods.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Any advice given by the Seller, in particular regarding the application of the delivered goods, shall only commit the Seller if given or confirmed in writing.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Copyright`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The Seller retains the property and copyright in drawings, sketches, cost estimates and the documents attached to any offers or order confirmation. The Buyer may only be entitled to use them to the agreed purpose and may not reproduce or disclose them to third parties without the prior written consent of the Seller. At the Seller’s request, such documents and any duplicates have to be rendered to the Seller.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The technology and the knowledge patented or not, used in the materials and services, all the rights of technical intellectual properties relating to the products and services, remain the exclusive property of KROHNE. Only is conceded to the customer a right of user of the materials on a purely non-exclusive basis.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`With regard to the delivered Software, the Seller is not aware that the use of the Software will violate any third party’s industrial property rights. The Seller assumes no liability for Software free from third parties’ industrial property rights and copyrights, excluding industrial property rights in the Federal Republic of Germany. This shall also apply, if the Buyer uses the Software outside the Federal Republic of Germany, irrespectively of whether or not the Seller has been informed by the Buyer in advance thereof. If the use violates industrial property rights, the Seller is allowed at his own option either to alter the Software to a reasonable scope for the Buyer so it is excluded from the extent of protection or to obtain the right for the Buyer to use the Software in an unlimited way without paying additional costs. In case of other defects of title the terms in Sec. 8.10 above shall apply.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Disposal of the products`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`The Buyer shall be committed at the end of the use of the delivered products to take care of the proper disposal according to the legal and statutory provisions and requirements applicable as of the end of the use of the delivered products. The Buyer shall hold harmless the Seller of any applicable statutory recovery obligations and requirements, in particular from any applicable obligations and requirements pursuant to § 10 subsec. 2 of the German Code on the Circulation, the Recovery and the environmentally sound Disposal of Electrical and Electronic Equipment (German WEEE Code) as well as from any claims of third parties connected therewith.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`In case the Buyer will pass on the delivered products to users other than private households the Buyer shall be obligated to contractually commit such users to take care of the proper disposal of the delivered products according to the statutory and legal provisions and requirements applicable when the use of the delivered products has ended at his expense and shall further be obligated in case the delivered products are again passed to another user to agree on a respective commitment with such user. If the Buyer failed to agree on such commitment, he shall be committed to recover the delivered products at his expense after the use of the delivered products has ended and to dispose them properly according to the statutory and legal provisions and requirements applicable as of the end of the use of the delivered products.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The claim of the Seller to take care of the disposal respectively to hold harmless Buyer pursuant to this subsec. 11 shall not be time barred until the end of two years after the final end of the use of the delivered products.`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Place of performance, jurisdiction and applicable law`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Place of performance for Seller’s delivery is ex works of the appropriate KROHNE factory.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`The place of jurisdiction is Duisburg. The Seller shall also to be entitled to bring proceedings against the Buyer at his place of residence.`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`All legal relations between the Seller and the Buyer shall be governed by German law as applicable for legal relations of domestic contracting parties. The United Nations\` Convention on the International Sale of Goods dated April 11, 1980 shall be excluded.`}</p>
          </li>
        </ol>
      </li>
    </ol>
    <p>{`February 2011`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      